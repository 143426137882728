import VueRouter from "vue-router";

const FullMap = () => import("@/pages/FullMapPage");
const ReportPage = () => import("@/pages/ReportPage");
const ReportPageParticipant = () => import("@/pages/ReportPageParticipant");
const Charts = () => import("@/pages/GoodBadChartPage");
const StatsComposition = () => import("@/pages/StatsPage");
const DataSets = () => import("@/pages/DatasetPage");
const ComplyOrExplain = () => import("@/pages/ComplyOrExplainPage");
const About = () => import("@/pages/InfoPage");
const Dev = () => import("@/pages/DevelopmentPage");
const MultiMapLanding = () => import("@/pages/MultiMapLandingPage");
const SingleMapPressPage = () => import("@/pages/SingleMapPressPage");
const MetricsPage = () => import("@/pages/MetricsPage");
const TrackingCookiesPage = () => import("@/pages/TrackingCookiesPage");
const LoginPlazaPage = () => import("@/pages/LoginPlazaPage");
const StatePage = () => import("@/pages/StatePage");
const SettingsPage = () => import("@/pages/SettingsPage");
const NotFound = () => import("@/pages/NotFound");
const ErrorPage = () => import("@/pages/ErrorPage");
const ManagePage = () => import("@/pages/ManagePage");
const LeaderBoardPage = () => import("@/components/leaderboard/LeaderBoardPage");
const PublicSubmissionPage = () => import("@/components/public_submissions/PublicSubmissionPage");
import Vue from 'vue'
const MetricProgressPage = () => import("@/pages/MetricProgressPage");

import i18n from './i18n';
import store from './store';

const routes = [
  {
    path: '/maps',
    component: MultiMapLanding,
    alias: '/',
    name: "maps",
    meta: {'menu': 'app', 'basename': 'maps'},
  },
  // frontpage menu items
  {
    path: '/datasets',
    component: DataSets,
    name: "datasets",
    meta: {'menu': 'app', 'basename': 'datasets'},
  },

  {
    path: '/map',
    redirect: '/map/NL/municipality',
    name: "map",
    meta: {'menu': 'organization', 'basename': 'map'},
  },
  {
    path: '/map/:layer_or_landing_page',
    component: FullMap,
    name: "map_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'map'},
  },
  {
    path: '/map/:country/:layer/:report_id',
    component: FullMap,
    name: "map_with_country_and_layer_and_report_id",
    meta: {'menu': 'organization', 'basename': 'map'},
    props: true,
  },
  {
    path: '/map/:country/:layer',
    component: FullMap,
    name: "map_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'map'},
  },
  // {
  //   path: '/map/:country/:layer/:week',
  //   component: FullMap,
  //   name: "map_with_country_and_layer_and_week",
  //   meta: {'menu': 'organization', 'basename': 'map'},
  // },
  {
    path: '/press',
    component: SingleMapPressPage,
    name: "press",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/sector-report/:country/:layer',
    component: SingleMapPressPage,
    name: "sector_report",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
   {
    path: '/sector-report/:layer_or_landing_page/:date',
    component: SingleMapPressPage,
    name: "sector_report_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/sector-report/:layer_or_landing_page/:date/:days_back',
    component: SingleMapPressPage,
    name: "sector_report_with_country_or_layer",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/press/:layer_or_landing_page/:date',
    component: SingleMapPressPage,
    name: "press_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/press/:layer_or_landing_page/:date/:days_back',
    component: SingleMapPressPage,
    name: "press_with_country_or_layer",
    meta: {'menu': 'organization', 'basename': 'sector-report'},
  },
  {
    path: '/report',
    component: ReportPage,
    name: "report",
    meta: {'menu': 'organization', 'basename': 'report'},
  },
  {
    path: '/report/:report_id',
    component: ReportPage,
    name: "legacy-reports",
    meta: {'menu': 'organization', 'basename': 'report'},
  },
  {
    path: '/report_participant/:report_id',
    component: ReportPageParticipant,
    name: "report-for-participants-and-plus",
    meta: {'menu': 'organization', 'basename': 'report'},
  },
  {
    path: '/report/:country/:layer',
    component: ReportPage,
    name: "reports-with-country_and_layer",
    meta: {'menu': 'organization', 'basename': 'report'},
  },
  {
    path: '/report/:country/:layer/:report_id',
    component: ReportPage,
    name: "report_with_country_and_layer_and_id",
    meta: {'menu': 'organization', 'basename': 'report'},
  },
  {
    path: '/report/:tab/:country/:layer/:report_id',
    component: ReportPage,
    name: "report_with_tab_country_and_layer_and_id",
    meta: {'menu': 'organization', 'basename': 'report'},
  },
  {
    path: '/login-plaza',
    component: LoginPlazaPage,
    name: "login-plaza",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/login-plaza/:search_query',
    component: LoginPlazaPage,
    name: "login-plaza-search",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/login-plaza/:country/:layer/',
    component: LoginPlazaPage,
    name: "login-plaza-with-country_and_layer",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/login-plaza/:country/:layer/:search_query',
    component: LoginPlazaPage,
    name: "login-plaza-with-country_and_layer-search",
    meta: {'menu': 'organization', 'basename': 'login-plaza'},
  },
  {
    path: '/charts',
    component: Charts,
    name: "charts",
    meta: {'menu': 'organization', 'basename': 'charts'},
  },
  {
    path: '/charts/:layer_or_landing_page',
    component: Charts,
    name: "charts_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'charts'},
  },
  {
    path: '/charts/:country/:layer',
    component: Charts,
    name: "charts_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'charts'},
  },

  {
    path: '/stats',
    component: StatsComposition,
    alias: '/statistics',
    name: "statistics",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },
  {
    path: '/stats/:layer_or_landing_page',
    component: StatsComposition,
    alias: '/statistics',
    name: "statistics_with_layer_or_landing_page",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },
  {
    path: '/stats/:country/:layer',
    component: StatsComposition,
    alias: '/statistics',
    name: "stats_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },
  {
    path: '/statistics/:country/:layer',
    component: StatsComposition,
    alias: '/statistics',
    name: "statistics_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'statistics'},
  },


  {
    path: '/comply-or-explain',
    component: ComplyOrExplain,
    name: "comply_or_explain",
    meta: {'menu': 'organization', 'basename': 'comply-or-explain'},
  },
  {
    path: '/comply-or-explain/:country/:layer',
    component: ComplyOrExplain,
    name: "comply_or_explain_with_country_and_layer",
    meta: {'menu': 'organization', 'basename': 'comply-or-explain'},
  },
  {
    path: '/metrics',
    component: MetricsPage,
    name: "metrics",
    meta: {'menu': 'app', 'basename': 'metrics'},
  },
  {
    path: '/about',
    component: About,
    alias: '/info',
    name: "info",
    meta: {'menu': 'app', 'basename': 'info'},
  },
  {
    path: '/dev',
    component: Dev,
    name: "dev",
    meta: {'menu': 'app', 'basename': 'dev'},
  },
  {
    path: '/state',
    component: StatePage,
    name: "app-state",
    meta: {'menu': 'app', 'basename': 'state'},
  },
  {
    path: '/state/:country/:layer',
    component: StatePage,
    name: "app-state-with-country-and-layer",
    meta: {'menu': 'app', 'basename': 'state'},
  },
    {
    path: '/settings',
    component: SettingsPage,
    name: "app-settings",
    meta: {'menu': 'app', 'basename': 'settings'},
  },
  {
    path: '/metric-progress/',
    component: MetricProgressPage,
    name: "metric-progress",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
  {
    path: '/metric-progress/:filter',
    component: MetricProgressPage,
    name: "metric-progress-with-filter",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
  {
    path: '/metric-progress/:country/:layer',
    component: MetricProgressPage,
    name: "metric-progress-with-country_and_layer",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
  {
    path: '/metric-progress/:country/:layer/:filter',
    component: MetricProgressPage,
    name: "metric-progress-with-country_and_layer-with-filter",
    meta: {'menu': 'organization', 'basename': 'metric-progress'},
  },
  {
    path: '/tracking-cookies/',
    component: TrackingCookiesPage,
    name: "tracking-cookies",
    meta: {'menu': 'app', 'basename': 'tracking-cookies'},
  },
    {
    path: '/leaderboard/',
    component: LeaderBoardPage,
    name: "leaderboard",
    meta: {'menu': 'app', 'basename': 'leaderboard'},
  },
  // page for 404/notfound
  {
    path: '/notfound', component: NotFound,
    meta: {'basename': 'not-found'},
  },
  // error page
  {
    path: '/error', component: ErrorPage,
    meta: {'basename': 'error'},
  },
  {
    path: '/manage',
    component: ManagePage,
  },
    {
    path: '/public-submissions/',
    component: PublicSubmissionPage,
    name: "public-submissions",
    meta: {'menu': 'app', 'basename': 'public-submissions'},
  },

  // redirect every unroutable url to the notfound page explicitly
  // this to make it show up in server logs/metrics
  // everything below this line will not resolve as this catches all other routes
  {
    path: '/:pathMatch(.*)',
    redirect: '/notfound',
  },


];


const router = new VueRouter({
  routes, // short for `routes: routes`
  mode: 'history',
  props: true,
  // https://reactgo.com/scroll-to-anchor-tags-vue-router/
  // does not work, as nested anchors is not a thing (and not reliable). So do this in component.
  scrollBehavior: function (to) {
    if (to.hash) {
      return {selector: to.hash}
    }
  },
});


router.beforeEach(async (to, from, next) => {
  // convert old style # urls to plain urls
  if (to.fullPath.substr(0,2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  // only route to valid/published layers
  if (to.params.country && store.state.config.country_and_layers[to.params.country]['layers'].indexOf(to.params.layer) == -1){
    // don't use Vue routing here so this will force the browser to request the page and cause a log entry
    // in the server logs, so we can track 404's
    window.location.replace("/notfound");
    return;
  }
  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    // initialization issue.
    if (store.state.config.project.name)
      document.title =  to.name ? `${store.state.config.project.name} - ${i18n.t(`app.menu.${to.meta.basename}`)}` : 'Basisbeveiliging';
    else
      document.title = to.name ? i18n.t(`app.menu.${to.meta.basename}`) : 'Basisbeveiliging';
  });
})


export default router
